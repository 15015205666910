import { Button } from '@mui/material';

// import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Indicateur1 from './Indicateur/1'
import Indicateur2 from './Indicateur/2'

import Indicateur3 from './Indicateur/3'

import Indicateur4 from './Indicateur/4'

import Indicateur5 from './Indicateur/5'

import Indicateur6 from './Indicateur/6'

import Indicateur7 from './Indicateur/7'

import Indicateur8 from './Indicateur/8'
import Manuel from './Manuel/manuel'
import Domaine from './Manuel/domaine'
import Programme from './Programme';
import axios from 'axios';
import { api } from '../../../api';
import Load from '../../components/load';
import Notification from '../../components/feedback/Notification';
// import Button from '../../components/btn/Button';


export default function ABF() {
    const { id } = useParams()
    const { data, activeStep, setActiveStep } = useOutletContext()
    const [page, setPage] = useState(1)
    const [options, setOptions] = useState({
        loading: false
    })
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false
    })
    const [pageManuel, setPageManuel] = useState(1)
    function register() {
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append("entrepriseFamille1", data.entrepriseFamille1)
            form.append("entrepriseFamille2", data.entrepriseFamille2)
            form.append("entrepriseFamille3", data.entrepriseFamille3)
            form.append("marketing1", data.marketing1)
            form.append("marketing2", data.marketing2)
            form.append("marketing3", data.marketing3)
            form.append("approvisionnement1", data.approvisionnement1)
            form.append("approvisionnement2", data.approvisionnement2)
            form.append("approvisionnement3", data.approvisionnement3)
            form.append("gestionStock1", data.gestionStock1)
            form.append("gestionStock2", data.gestionStock2)
            form.append("gestionStock3", data.gestionStock3)
            form.append("cout1", data.cout1)
            form.append("cout2", data.cout2)
            form.append("cout3", data.cout3)
            form.append("tenueRegistre1", data.tenueRegistre1)
            form.append("tenueRegistre2", data.tenueRegistre2)
            form.append("tenueRegistre3", data.tenueRegistre3)
            form.append("planificationFin1", data.planificationFin1)
            form.append("planificationFin2", data.planificationFin2)
            form.append("planificationFin3", data.planificationFin3)
            form.append("persoProd1", data.persoProd1)
            form.append("persoProd2", data.persoProd2)
            form.append("persoProd3", data.persoProd3)
            form.append("manuelMarketing", data.Marketing)
            form.append("manuelApprov", data.Approvisionnement)
            form.append("manuelGestStock", data.gestionStocks)
            form.append("manuelCout", data.couts)
            form.append("manuelCompta", data.Comptabilité)

            form.append("manuelPlanFin", data.planuficationFinanciere)
            form.append("manuelPersoProd", data.personnel)
            form.append("infoMarche", data.infoMarche)
            form.append("registreCom", data.registreCommerce)
            form.append("assistanceJur", data.AssistanceJuridique)
            form.append("infoTechno", data.infoTechno)
            form.append("formationTech", data.formationTech)
            form.append("infoTechno", data.infoTechno)
            form.append("assurance", data.assurance)
            form.append("credit", data.credit)
            form.append("deviseEtrangere", data.devises)
            form.append("matierePremiere", data.mp)
            form.append("associationGroup", data.Associations)

            form.append("autre1", data.autre1)
            form.append("autre2", data.autre2)
            form.append("form1Montant1", data.form1Montant1)
            form.append("form1Date", data.form1Date)
            form.append("form1Montant2", data.form1Montant2)
            // form.append("form1Pourcentage",data.i11)
            form.append("form2Montant1", data.form2Montant1)
            form.append("form2Date", data.form2Date)
            form.append("form2Montant2", data.form2Montant2)
            // form.append("form2Pourcentage",data.i11)
            form.append("form3Montant1", data.form3Montant1)
            form.append("form3Date", data.form3Date)
            form.append("form3Montant2", data.form3Montant2)
            // form.append("form3Pourcentage",data.i11)
            form.append("form4Montant1", data.form4Montant1)
            form.append("form4Date", data.form4Date)
            form.append("form4Montant2", data.form4Montant2)
            // form.append("form4Pourcentage",data.i11)
            form.append("form5Montant1", data.form5Montant1)
            form.append("form5Date", data.form5Date)

            form.append("form5Montant2", data.form5Montant2)
            // form.append("form5Pourcentage",data.i11)
            form.append("form6Montant1", data.form6Montant1)
            form.append("form6Date", data.form6Date)
            form.append("form6Montant2", data.form6Montant2)

            // form.append("form6Pourcentage",data.i11)
            form.append("form7Montant1", data.form7Montant1)
            form.append("form7Date", data.form7Date)
            form.append("form7Montant2", data.form7Montant2)
            // form.append("form7Pourcentage",data.i11)
            form.append("form8Montant1", data.form8Montant1)
            form.append("form8Date", data.form8Date)
            form.append("form8Montant2", data.form8Montant2)
            form.append("form8Pourcentage", data.form8Pourcentage)


            axios.post(api + '/api/inscriptionFormation/' + localStorage.getItem('naturagerme-id') + "/" + id, form)
                .then(res => {
                    console.log(res.data)
                    setOptions({ ...options, loading: false })
                   
                    setIsOpen({...isOpen, success: true })
                    setTimeout(() => {
                        navigate('/Formation')
                    }, 500);

                })
                .catch(err => {
                    console.error(err)
                    setOptions({ ...options, loading: false })
                    setIsOpen({...isOpen, fails: true })

                })
        } catch (error) {
            console.error(error)
            setOptions({ ...options, loading: false })
            setIsOpen({...isOpen, fails: true })

        }
    }


    useEffect(() => {
        if (page > 8) {
            setActiveStep(prev => prev += 1)
            setPage(1)
        }
    }, [page])
    useEffect(() => {
        if (pageManuel > 2) {
            setActiveStep(prev => prev += 1)
            setPageManuel(1)
        }
    }, [pageManuel])
    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className='w-full'>
            <div>
                {
                    activeStep === 0 && (
                        <>
                            {
                                page === 1 && (
                                    <Indicateur1 setPage={setPage}/>
                                )
                            }
                            {
                                page === 2 && (
                                    <Indicateur2 setPage={setPage}/>
                                )
                            }
                            {
                                page === 3 && (
                                    <Indicateur3 setPage={setPage}/>
                                )
                            }
                            {
                                page === 4 && (
                                    <Indicateur4 setPage={setPage}/>
                                )
                            }
                            {
                                page === 5 && (
                                    <Indicateur5 setPage={setPage}/>
                                )
                            }
                            {
                                page === 6 && (
                                    <Indicateur6 setPage={setPage}/>
                                )
                            }
                            {
                                page === 7 && (
                                    <Indicateur7 setPage={setPage}/>
                                )
                            }
                            {
                                page === 8 && (
                                    <Indicateur8 setPage={setPage}/>
                                )
                            }
                            {/* <div className='w-full flex flex-row justify-end items-center'>
                                <Button variant='contained' style={{ backgroundColor: 'slategray' }} onClick={() => { setPage(prev => prev += 1) }}>
                                    Suivant
                                </Button>
                            </div> */}
                        </>
                    )
                }
                {
                    activeStep === 1 && (
                        <>
                            {
                                pageManuel === 1 && (
                                    <Manuel setPageManuel={setPageManuel}/>
                                )
                            }
                            {
                                pageManuel === 2 && (
                                    <Domaine setPageManuel={setPageManuel} />
                                )
                            }
                            {/* <div className='w-full mt-2 flex flex-row justify-end items-center'>
                                <Button variant='contained' style={{ backgroundColor: 'slategray' }} onClick={() => { setPageManuel(prev => prev += 1) }}>
                                    Suivant
                                </Button>
                            </div> */}
                        </>
                    )
                }
                {
                    activeStep === 2 && (
                        <form onSubmit={(e)=>{e.preventDefault();register()}}>
                            <Programme />
                            <div className='w-full mt-2 flex flex-row justify-end items-center'>
                                <Button variant='contained' style={{ backgroundColor: 'slategray' }} type="submit">
                                    <Load loading={options.loading} title={"Terminer"} />
                                </Button>
                            </div>
                        </form>
                    )
                }

            </div>
            <Notification type="success" message="Inscription reussi." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

        </div>
    )
}