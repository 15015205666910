
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import {
  // Document,
  // Page,
  // Text,
  // View,
  StyleSheet,
  // PDFViewer,
  // PDFDownloadLink,

} from "@react-pdf/renderer"
import { useParams } from 'react-router-dom';
import { api } from '../../../api';
import html2pdf from "html2pdf.js"
import { formatInDate } from '../../components/DateTimeConverter/DateTimeConverter';

// Define styles for the PDF
const styles = StyleSheet.create({
  firstpage: {
    flexDirection: 'column',
    justifyContent: 'center', // Centre verticalement
    alignItems: 'center', // Centre horizontalement
    padding: 20,
    paddingTop: 300,
    height: '100%', 
  },
  sec1:{
    paddingHorizontal: 40,
  },

  firstsection: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
section: {
  margin: 10,
  padding: 10,
  flexGrow: 1,
},
firstTitle: {
  fontSize: 18,
  textAlign: 'center',
  fontWeight: 'bold',
  paddingHorizontal:50,
  marginBottom: 100,
},
text: {
  fontSize: 12,
  textAlign: 'center',
  marginBottom: 5,
},
  page: {
    padding: 30,
    // lineHeight:"28px"
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
    marginBottom: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginTop: 10,
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '20%',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#e0e0e0',
    padding: 5,
    fontWeight: 'bold',
  },
  tableCol: {
    width: '20%',
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
  },
  tableCellHeader: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  tableCell: {
    fontSize: 12,
  },
});

const lists = ["Entreprise et famille", "Marketing", "Approvisionnement", "Gestion des stocks", "Coûts", "Tenue des registres", "Planification financière", "Personnel et prodductivité"]
function list_desire(item1, item2, item3, list) {
  if (item1 >= 0 && item2 > 0 && item3 > 0) {
    return list
  } else {
    return ""
  }
}
let count = {
  feminin: 0,
  masculin: 0,
  secondary: 0,
  baccalaureat: 0,
  superieur: 0,

}
function init_gender_count() {
  count.feminin = 0
  count.masculin = 0
  count.secondary = 0
  count.baccalaureat = 0
  count.superieur = 0
}
function genderCount(item) {
  if (item === "Masculin") {
    count.masculin += 1
  }
  if (item === "Feminin") {
    count.feminin += 1
  }
  console.log(item)
}
function levelCount(item){
  return ""
}

// PDF document component
const MyDocument = ({ data, formData, ageGroups, cleNotation, experienceInterval, formation }) => (

  <>
      <div className="p-6 space-y-8">
      {/* First Section */}
      <div className="bg-blue-100 p-6 rounded-lg shadow mt-[250px] mb-[500px]">
        <h1 className="text-2xl font-bold text-center text-blue-900">
          RAPPORT SUR L’ANALYSE DES BESOINS EN FORMATION OU ABF
        </h1>
        <div className="mt-6 space-y-2">
          <p className='text-black-4'> <span className="font-semibold">Date : </span> {formatInDate(formation?.dateDebut)} et {formatInDate(formation?.dateFin)}</p>
          <p className='text-black-4'> <span className="font-semibold">Lieu : </span> {formation?.lieu}</p>
          <p className='text-black-4'> <span className="font-semibold">Objet : </span> Analyse de besoins en formation GERME</p>
          <p className='text-black-4'> <span className="font-semibold">Missionnaires : </span> Les formateurs GERME</p>
          <p className='text-black-4'>
            {formation?.liste_formateurs?.map((item, index) => item.user?.nom).join(', ')}
          </p>
        </div>
      </div>

      {/* Introduction */}
      <section className="space-y-6">
        <div>
          <h2 className="text-lg font-semibold mb-4">I. INTRODUCTION</h2>
          <p className='text-gray-950 leading-normal text-justify'>{formData.introduction}</p>
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-4">
            II. Objectifs de l’analyse des besoins en formation (ABF)
          </h2>
          {/* <p className='text-gray-950 leading-normal text-justify'>{formData.objectif}</p> */}
          <div
          
          style={{
      color: '#111111',
      textAlign: 'justify',
      lineHeight: '1.5',
      fontSize: '14px',
    }}

    className="className='text-xs text-gray-950 leading-normal text-justify"
    dangerouslySetInnerHTML={{
      __html: formatTextToHtml(formData.objectif),
    }}
  />
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-4">
            III. Résultats attendus de la mission d’analyse des besoins en formation (ABF)
          </h2>
          {/* <p className='text-gray-950 leading-normal text-justify'>{formData.resultat}</p> */}
          <div
          
          style={{
      color: '#111111',
      textAlign: 'justify',
      lineHeight: '1.5',
      fontSize: '14px',
    }}

    className="className='text-xs text-gray-950 leading-normal text-justify"
    dangerouslySetInnerHTML={{
      __html: formatTextToHtml(formData.resultat),
    }}
  />
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-4">
            VI. Récapitulatif des résultats de l’ABF Fournisseurs de NATURA MADAGASCAR
          </h2>
          <p className='text-gray-950 leading-normal text-justify'>{formData.recapitulatif}</p>
        </div>
      </section>

      {/* Entrepreneurs Table */}
      <section className="space-y-6" >
        <h2 className="text-lg font-semibold mb-4">
          Les {data?.length} entrepreneur(s) entretenus
        </h2>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-4 py-2">N°</th>
              <th className="border border-gray-300 px-4 py-2">Nom et prénoms</th>
              <th className="border border-gray-300 px-4 py-2">Sexe</th>
              <th className="border border-gray-300 px-4 py-2">Activité</th>
              <th className="border border-gray-300 px-4 py-2">Localité</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="even:bg-gray-100">

                <td className="text-gray-800  border border-gray-300 px-4 py-2">{index + 1}</td>
                <td className="text-gray-800  border border-gray-300 px-4 py-2">{item.user?.donnee_perso1?.nom} {item.user?.donnee_perso1?.prenom}</td>
                <td className="text-gray-800  border border-gray-300 px-4 py-2">{item.user?.donnee_perso1?.sexe}</td>
                <td className="text-gray-800  border border-gray-300 px-4 py-2">{item.user?.donnee_entreprise?.activiteEntreprise}</td>
                <td className="text-gray-800  border border-gray-300 px-4 py-2">{item.user?.donnee_perso1?.adressePostale}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Gender Distribution */}
      <section>
        <h2 className="text-lg font-semibold mb-4">Tableau des sexes des entrepreneurs</h2>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-4 py-2">FEMININ</th>
              <th className="border border-gray-300 px-4 py-2">MASCULIN</th>
              <th className="border border-gray-300 px-4 py-2">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-gray-950  border border-gray-300 px-4 py-2">{data.filter((item)=>item.user.donnee_perso1.sexe === "Féminin")?.length || "0"}</td>
              <td className="text-gray-950  border border-gray-300 px-4 py-2">{data.filter((item)=> item.user.donnee_perso1.sexe === "Masculin")?.length || "0"}</td>
              <td className="text-gray-950  border border-gray-300 px-4 py-2">{data.length}</td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Age Groups */}
      <section>
        <h2 className="text-lg font-semibold mb-4">Tableau des tranches d’âge</h2>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-4 py-2">Tranche d’âge</th>
              <th className="border border-gray-300 px-4 py-2">Effectif</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(ageGroups).map((ageGroup, index) => (
              <tr key={index} className="even:bg-gray-100">
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{ageGroup} ans</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{ageGroups[ageGroup]}</td>
              </tr>
            ))}
            <tr>
              <td className="text-gray-950  border border-gray-300 px-4 py-2 font-bold">Total</td>
              <td className="text-gray-950  border border-gray-300 px-4 py-2 font-bold">{data.length}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

      {/* ( */}
    <div className="p-6 space-y-8">
      {/* Table for Education Level */}
      {/* <div className="">
        <div className=""> */}
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="text-md font-semibold tracking-wide text-left text-gray-950 bg-gray-100 uppercase border-b border-gray-600">
                <th className="border border-gray-300 px-4 py-2">Secondaire</th>
                <th className="border border-gray-300 px-4 py-2">Baccalauréat</th>
                <th className="border border-gray-300 px-4 py-2">Supérieur</th>
                <th className="border border-gray-300 px-4 py-2">Total</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr className="text-gray-950">
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{data.filter((item) => item.user.donnee_perso1.niveauFormation === "Secondaire")?.length || "0"}</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{data.filter((item) => item.user.donnee_perso1.niveauFormation === "Baccalauréat")?.length || "0"}</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{data.filter((item) => item.user.donnee_perso1.niveauFormation === "Supérieur")?.length || "0"}</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{data.filter((item) => ["Secondaire", "Baccalauréat", "Supérieur"].includes(item.user.donnee_perso1.niveauFormation))?.length || "0"}</td>
              </tr>
            </tbody>
          </table>
        {/* </div>
      </div> */}

      {/* Table for Years of Experience */}
      {/* <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
        <div className="w-full overflow-x-auto"> */}
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="text-md font-semibold tracking-wide text-left text-gray-950 bg-gray-100 uppercase border-b border-gray-600">
                <th className="border border-gray-300 px-4 py-2">Années d’expérience</th>
                <th className="border border-gray-300 px-4 py-2">Effectif</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {Object.entries(experienceInterval).map(([interval, count]) => (
                <tr className="text-gray-950" key={interval}>
                  <td className="text-gray-950  border border-gray-300 px-4 py-2">{interval}</td>
                  <td className="text-gray-950  border border-gray-300 px-4 py-2">{count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        {/* </div>
      </div> */}

      {/* Key Rating Table */}
      {/* <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
        <div className="w-full overflow-x-auto"> */}
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="text-md font-semibold tracking-wide text-left text-gray-950 bg-gray-100 uppercase border-b border-gray-600">
                <th className="border border-gray-300 px-4 py-2">SFE</th>
                <th className="border border-gray-300 px-4 py-2">GAE</th>
                <th className="border border-gray-300 px-4 py-2">EI</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr className="text-gray-950">
                <td className="text-gray-950  border border-gray-300 px-4 py-2">0-51</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">52-62</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">63-72</td>
              </tr>
              <tr className="text-gray-950">
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{cleNotation?.countSFE}</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{cleNotation?.countGAE}</td>
                <td className="text-gray-950  border border-gray-300 px-4 py-2">{cleNotation?.countEI}</td>
              </tr>
            </tbody>
          </table>
        {/* </div>
      </div> */}

      {/* Training Needs Table */}
      {/* <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
        <div className="w-full overflow-x-auto"> */}
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="text-md font-semibold tracking-wide text-left text-gray-950 bg-gray-100 uppercase border-b border-gray-600">
                <th className="border border-gray-300 px-4 py-2">N°</th>
                <th className="border border-gray-300 px-4 py-2">Nom et prénoms</th>
                <th className="border border-gray-300 px-4 py-2">Domaine d'activité</th>
                <th className="border border-gray-300 px-4 py-2">Horaire</th>
                <th className="border border-gray-300 px-4 py-2">Besoin en formation</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.map((entrepreneur, index) => (

                <tr className="text-gray-800" key={index}>
                  <td className="text-gray-800  border border-gray-300 px-4 py-2">{index + 1}</td>
                  <td className="text-gray-800  border border-gray-300 px-4 py-2">{entrepreneur.user?.donnee_perso1?.nom} {entrepreneur.user?.donnee_perso1?.prenom}</td>
                  <td className="text-gray-800  border border-gray-300 px-4 py-2">{entrepreneur.user?.donnee_entreprise?.domaine}</td>
                  <td className="text-gray-800  border border-gray-300 px-4 py-2">{entrepreneur.user?.donnee_perso2?.horaireFormation}</td>
                  <td className="text-gray-800  border border-gray-300 px-4 py-2">
                  {entrepreneur.user?.formation_desire?.marketing === 1 ? 'Marketing, ' : ''}
                  {entrepreneur.user?.formation_desire?.achatControleStock === 1 ? 'Gestion des stocks, ' : ''}
                  {entrepreneur.user?.formation_desire?.estimationCout === 1 ? 'Coûts, ' : ''}
                  {entrepreneur.user?.formation_desire?.tenueRegistre === 1 ? 'Tenue des registres ' : ''}
                  {entrepreneur.user?.formation_desire?.planifierEntreprise === 1 ? 'Planification financière, ' : ''}
                  {entrepreneur.user?.formation_desire?.persoProd === 1 ? 'Personnel et productivité, ' : ''}
                  {entrepreneur.user?.formation_desire?.entrepriseFamille === 1 ? 'Entreprise et famille, ' : ''}
                  {entrepreneur.user?.formation_desire?.approvisionnement === 1 ? 'Approvisionnement, ' : ''}
                  {entrepreneur.user?.formation_desire?.autre === 1 ? 'Autre' : ''}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        {/* </div>
      </div> */}

      {/* Recommendations */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">VII. Recommandations</h2>
        <p className='text-gray-950 leading-normal text-justify'>{formData.recommandation}</p>
      </div>

      {/* Conclusion */}
      <div>
        <h2 className="text-lg font-semibold mb-4">IV. Conclusion</h2>
        <p className='text-gray-950 leading-normal text-justify'>{formData.conclusion}</p>
      </div>
      </div>
    {/* </Page> */}
  </>
)

const calculateAgeGroups = (data) => {
  const ageGroups = {
    '18-24': 0,
    '25-30': 0,
    '31-40': 0,
    '41-50': 0,
    '51-60': 0,
    '61-70': 0
  };

  const currentYear = new Date().getFullYear();

  data.forEach(item => {
    const birthYear = new Date(item.user.donnee_perso1.dateNaissance).getFullYear();
    const age = currentYear - birthYear;

    if (age >= 18 && age <= 24) {
      ageGroups['18-24']++;
    } else if (age >= 25 && age <= 30) {
      ageGroups['25-30']++;
    } else if (age >= 31 && age <= 40) {
      ageGroups['31-40']++;
    } else if (age >= 41 && age <= 50) {
      ageGroups['41-50']++;
    } else if (age >= 51 && age <= 60) {
      ageGroups['51-60']++;
    } else if (age >= 61 && age <= 70) {
      ageGroups['61-70']++;
    }
  })

  return ageGroups;
}

const calculateExperienceIntervals = (data) => {
  const counts = {
    '1-5 ans': 0,
    '6-10 ans': 0,
    '11-15 ans': 0,
    '16-20 ans': 0,
    '21-30 ans': 0,
    '31-40 ans': 0,
  };

  data.map((item) => {
    const experience =
      item?.user?.donnee_perso2?.anneeExpGest; // Accéder à l'année d'expérience

    // Vérifier et incrémenter l'intervalle correspondant
    if (experience >= 1 && experience <= 5) {
      counts['1-5 ans'] += 1;
    } else if (experience >= 6 && experience <= 10) {
      counts['6-10 ans'] += 1;
    } else if (experience >= 11 && experience <= 15) {
      counts['11-15 ans'] += 1;
    } else if (experience >= 16 && experience <= 20) {
      counts['16-20 ans'] += 1;
    } else if (experience >= 21 && experience <= 30) {
      counts['21-30 ans'] += 1;
    } else if (experience >= 31 && experience <= 40) {
      counts['31-40 ans'] += 1;
    }
  });
console.log("tranche d'âges: ", counts)
  return counts;
};


const calculateTotalPoints = (abf1) => {
  if (!abf1) return 0;

  return (
    abf1.entrepriseFamille1 +
    abf1.entrepriseFamille2 +
    abf1.entrepriseFamille3 +
    abf1.marketing1 +
    abf1.marketing2 +
    abf1.marketing3 +
    abf1.approvisionnement1 +
    abf1.approvisionnement2 +
    abf1.approvisionnement3 +
    abf1.gestionStock1 +
    abf1.gestionStock2 +
    abf1.gestionStock3 +
    abf1.cout1 +
    abf1.cout2 +
    abf1.cout3 +
    abf1.tenueRegistre1 +
    abf1.tenueRegistre2 +
    abf1.tenueRegistre3 +
    abf1.planificationFin1 +
    abf1.planificationFin2 +
    abf1.planificationFin3 +
    abf1.persoProd1 +
    abf1.persoProd2 +
    abf1.persoProd3
  );
};

// Fonction pour classifier et compter les catégories SFE, GAE, EI
const classifyCategories = (data) => {
  let countSFE = 0;
  let countGAE = 0;
  let countEI = 0;

  data.map((item) => {
    const totalPoints = calculateTotalPoints(item.abf1);

    if (totalPoints >= 0 && totalPoints <= 51) {
      countSFE++;
    } else if (totalPoints >= 52 && totalPoints <= 62) {
      countGAE++;
    } else if (totalPoints >= 63 && totalPoints <= 72) {
      countEI++;
    }
  });

  return { countSFE, countGAE, countEI };
}

function formatTextToHtml(text) {
  return text
    .split("\n")
    .map((line) => {
      return `<p style="color: #111111; font-size: 14px; line-height: 1.5; margin: 0 0 10px; text-align: justify;">${line.trim()}</p>`;
    })
    .join("");
}



// Main component
const RapportHTML2PDF = () => {
  const [data, setData] = useState({
    // 'introduction': "",
    //  'objectif':"", 
    //  'resultat': "", 
    //  'recapitulatif': "", 
    //  'recommandation': "", 
    //  'conclusion': "",

    'introduction' :"Le GERME (Gestion et Renforcement des Moyennes et petites Entreprises) est un programme en gestion et en création d’entreprise conçu par le BIT (Bureau International du Travail) pour permettre aux petits entrepreneurs, potentiels ou en activité, de lancer des activités viables ou d'accroître la profitabilité des entreprises en activité. Ce programme novateur vise à favoriser le développement de projets d'entreprise viables et à accroître la rentabilité des entreprises déjà en opération, avec pour finalité la création d'emplois de qualité.",
    'objectif': "L’analyse des besoins en formation (ABF) vise à : \n 1.	Identifier les besoins spécifiques en formation GERME des fournisseurs de NATURA MADAGASCAR afin d’assurer le développement et la bonne gestion de leur entreprise, de répondre aux demandes de leurs produits sur TSENA VITA MALAGASY et de recruter des jeunes en milieu rural. \n 2.	Déterminer les domaines de compétences nécessaires pour renforcer les capacités des entrepreneurs locaux dans la création, la gestion et le développement d'entreprises rentables et durables. \n3.	Évaluer les défis et les lacunes actuels rencontrés par les entrepreneurs dans la gestion quotidienne de leurs activités, en mettant l'accent sur les aspects liés à la rentabilité, à la pérennité et à la création d'emplois de qualité. \n4.	Recueillir des données sur les besoins spécifiques en formation en gestion d'entreprise, en marketing, en gestion financière, en planification stratégique, ainsi que sur d'autres compétences pertinentes pour favoriser le succès entrepreneurial.",
    'resultat':"La mission pour l’analyse des besoins en formation (ABF) au niveau des fournisseurs de NATURA MADAGASCAR dans la région de Vakinakaratra devrait apporter les résultats suivants :"+
        "\na) Un rapport détaillé présentant une liste exhaustive des besoins en formation identifiés auprès des 35 fournisseurs sélectionnés, en utilisant les données recueillies à partir du guide d'entretien GERME et des fiches de candidatures."+
        "\nb) Une analyse approfondie des données collectées, mettant en évidence les principales tendances et lacunes dans les compétences entrepreneuriales des participants, ainsi que les domaines prioritaires nécessitant un renforcement."+
        "\nc) Une évaluation des besoins spécifiques en formation en gestion d'entreprise, en marketing, en gestion financière, en développement des compétences personnelles et professionnelles, et autres domaines pertinents selon les profils des entrepreneurs interrogés."+
        "\nd) Une identification claire des opportunités de croissance et d'amélioration pour les entreprises existantes, en mettant en évidence les domaines où des formations supplémentaires pourraient avoir un impact significatif sur la rentabilité et la pérennité des activités.",
    'déroulement': "Le résultat de l’analyse des besoins en formation ou ABF des fournisseurs de NATURA MADAGASCAR- TSENA VITA MALAGASY dans la Région de Vakinankaratra est annexé à ce rapport mais le récapitulatif est ci-dessous :",
    'recapitulatif': "Le résultat de l’analyse des besoins en formation ou ABF des fournisseurs de NATURA MADAGASCAR- TSENA VITA MALAGASY dans la Région de Vakinankaratra est annexé à ce rapport mais le récapitulatif est ci-dessous :",
    'recommandation': "Utilisation et gestion de la plateforme e-commerce Tsena Vita Malagasy : Ce module formera les entrepreneurs à utiliser efficacement la plateforme e-commerce, à gérer leurs produits en ligne, à optimiser leurs ventes et à tirer parti des fonctionnalités offertes par Tsena Vita Malagasy pour accroître leur visibilité et leur rentabilité.",
    'conclusion': "Suite à l'analyse des données, nous avons conclu que les fournisseurs ont besoin de formation dans les modules suivants :"+
      "\n1.	Planification financière : Ce module vise à doter les entrepreneurs des compétences nécessaires pour gérer efficacement leurs finances, planifier les investissements, et assurer la rentabilité de leur entreprise."+
      "\n2.	Personnel et productivité : Ce module a pour objectif d'améliorer la gestion des ressources humaines, d'augmenter la productivité et d'optimiser les performances globales de l'entreprise."+
      "\nCes modules de formation permettent aux fournisseurs de NATURA MADAGASCAR de renforcer leurs compétences en gestion d'entreprise, contribuant ainsi à la croissance et à la durabilité de leurs activités."
  })

  const [allInfo, setAllInfo] = useState([])
  const [formation, setFormation] = useState({})
  const [training, setTraining] = useState([])
  const [ageGroups, setAgeGroups] = useState({})
  const [cleNotation, setCleNotation] = useState({
     "countSFE" : 0,
     "countGAE" : 0,
     "countEI" : 0
  })
  const [intervalCounts, setIntervalCounts] = useState({
    '1-5 ans': 0,
    '6-10 ans': 0,
    '11-15 ans': 0,
    '16-20 ans': 0,
    '21-30 ans': 0,
    '31-40 ans': 0,
  });
  // const [besoins_formation,set_besoins_formation]=useState([])

  // const user_id = localStorage.getItem('naturagerme-id
  const {id} = useParams()
  const contentRef = useRef(null);

  useEffect(() => {
    // api pour liste de besoin de formation  

    const form = new FormData()
    form.append("formation_id", id)

    axios.post(api+'/api/getRapportABS', form)
    .then((response) => {
      // setData(response.data);
      
      setAllInfo(response.data?.inscription)
      setFormation(response.data?.formation)
      console.log("Rapport ABF ",response.data)
      const groups = calculateAgeGroups(response.data?.inscription)
      setAgeGroups(groups);
      setIntervalCounts(calculateExperienceIntervals(response.data?.inscription))
      console.log("Age Group", groups)

      setCleNotation(classifyCategories(response.data?.inscription))
      console.log("Clé de notation", cleNotation)
      console.log("Années d'expérience", intervalCounts)
    })
    .catch((error) => {
      console.error('Error fetching data: ', error);
    })


  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handlePrintPDF = () => {
    const options = {
      margin:       0.4,
      filename:     'rapport.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 4 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(contentRef.current).set(options).save(); // Print the content of MyDocument
  };

  return (
    <>
      {/* <Header title="Rapport" /> */}
      <div className='border-line p-6 mb-4 mr-6'>
        <div className='flex w-full justify-between items-center mb-6'>
          {/* <h1 className='text-xl font-semibold'>Information générale sur le rapport d'un ABF</h1>
          <PDFDownloadLink
            document={<MyDocument formData={data} data={allInfo} ageGroups={ageGroups} cleNotation={cleNotation} ageIntervalCount={intervalCounts} experienceInterval={intervalCounts} formation={formation}/>}
            fileName="rapport.pdf"
            className="btn-black"
          >
            {({ loading }) => (loading ? 'Chargement...' : 'Imprimer en PDF')}
          </PDFDownloadLink> */}
            <div className="flex w-full justify-between items-center mb-6">
          <h1 className="text-xl font-semibold">Information générale sur le rapport d'un ABF</h1>
          <button className="btn-black" onClick={handlePrintPDF}>Imprimer en PDF</button> {/* Button triggers PDF generation */}
        </div>
<div className="hidden">
        <div ref={contentRef}> {/* This is where the content for PDF printing is */}
          <MyDocument formData={data} data={allInfo} ageGroups={ageGroups} cleNotation={cleNotation} ageIntervalCount={intervalCounts} experienceInterval={intervalCounts} formation={formation} />
        </div>
</div>
        </div>

        <div className=''>
          {[
            {
              title:'introduction',
              text:"Le GERME (Gestion et Renforcement des Moyennes et petites Entreprises) est un programme en gestion et en création d’entreprise conçu par le BIT (Bureau International du Travail) pour permettre aux petits entrepreneurs, potentiels ou en activité, de lancer des activités viables ou d'accroître la profitabilité des entreprises en activité. Ce programme novateur vise à favoriser le développement de projets d'entreprise viables et à accroître la rentabilité des entreprises déjà en opération, avec pour finalité la création d'emplois de qualité."
            }, 
            {
              title:'objectif',
              text: "L’analyse des besoins en formation (ABF) vise à : \n 1.	Identifier les besoins spécifiques en formation GERME des fournisseurs de NATURA MADAGASCAR afin d’assurer le développement et la bonne gestion de leur entreprise, de répondre aux demandes de leurs produits sur TSENA VITA MALAGASY et de recruter des jeunes en milieu rural. \n 2.	Déterminer les domaines de compétences nécessaires pour renforcer les capacités des entrepreneurs locaux dans la création, la gestion et le développement d'entreprises rentables et durables. \n3.	Évaluer les défis et les lacunes actuels rencontrés par les entrepreneurs dans la gestion quotidienne de leurs activités, en mettant l'accent sur les aspects liés à la rentabilité, à la pérennité et à la création d'emplois de qualité. \n4.	Recueillir des données sur les besoins spécifiques en formation en gestion d'entreprise, en marketing, en gestion financière, en planification stratégique, ainsi que sur d'autres compétences pertinentes pour favoriser le succès entrepreneurial."
            }, 
            {
              title:'resultat',
              text:"La mission pour l’analyse des besoins en formation (ABF) au niveau des fournisseurs de NATURA MADAGASCAR dans la région de Vakinakaratra devrait apporter les résultats suivants :"+
                "\na) Un rapport détaillé présentant une liste exhaustive des besoins en formation identifiés auprès des 35 fournisseurs sélectionnés, en utilisant les données recueillies à partir du guide d'entretien GERME et des fiches de candidatures."+
                "\nb) Une analyse approfondie des données collectées, mettant en évidence les principales tendances et lacunes dans les compétences entrepreneuriales des participants, ainsi que les domaines prioritaires nécessitant un renforcement."+
                "\nc) Une évaluation des besoins spécifiques en formation en gestion d'entreprise, en marketing, en gestion financière, en développement des compétences personnelles et professionnelles, et autres domaines pertinents selon les profils des entrepreneurs interrogés."+
                "\nd) Une identification claire des opportunités de croissance et d'amélioration pour les entreprises existantes, en mettant en évidence les domaines où des formations supplémentaires pourraient avoir un impact significatif sur la rentabilité et la pérennité des activités."
            }, 
             {
              title:'déroulement',
              text: "Le résultat de l’analyse des besoins en formation ou ABF des fournisseurs de NATURA MADAGASCAR- TSENA VITA MALAGASY dans la Région de Vakinankaratra est annexé à ce rapport mais le récapitulatif est ci-dessous :"
            },
            {
              title:'recapitulatif',
              text: "Le résultat de l’analyse des besoins en formation ou ABF des fournisseurs de NATURA MADAGASCAR- TSENA VITA MALAGASY dans la Région de Vakinankaratra est annexé à ce rapport mais le récapitulatif est ci-dessous :"
            },
            {
              title:'recommandation',
              text: "Utilisation et gestion de la plateforme e-commerce Tsena Vita Malagasy : Ce module formera les entrepreneurs à utiliser efficacement la plateforme e-commerce, à gérer leurs produits en ligne, à optimiser leurs ventes et à tirer parti des fonctionnalités offertes par Tsena Vita Malagasy pour accroître leur visibilité et leur rentabilité."
            },
            {
              title:'conclusion',
              text: "Suite à l'analyse des données, nous avons conclu que les fournisseurs ont besoin de formation dans les modules suivants :"+
"\n1.	Planification financière : Ce module vise à doter les entrepreneurs des compétences nécessaires pour gérer efficacement leurs finances, planifier les investissements, et assurer la rentabilité de leur entreprise."+
"\n2.	Personnel et productivité : Ce module a pour objectif d'améliorer la gestion des ressources humaines, d'augmenter la productivité et d'optimiser les performances globales de l'entreprise."+
"\nCes modules de formation permettent aux fournisseurs de NATURA MADAGASCAR de renforcer leurs compétences en gestion d'entreprise, contribuant ainsi à la croissance et à la durabilité de leurs activités."
            }].map((field, index) => (
            <div key={index} className='input-form'>
              <label htmlFor={field.title} className="block text-sm font-medium text-gray-950 text-start">
                {field.title.charAt(0).toUpperCase() + field.title.slice(1).replace(/_/g, ' ')}
              </label>
              
              <textarea
                id={field.title}
                name={field.title}

                className="input-textarea h-[200px]"

                placeholder={`Ajouter ${field.title}`}
                value={data[field.title]}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>
       </div>
     </>

  );
};

export default RapportHTML2PDF;





