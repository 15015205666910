import React from 'react';
import { FaEnvelope, FaFacebook, FaLinkedin, FaPhone, FaPhoneAlt, FaPhoneSquare } from 'react-icons/fa';

export default function About() {
  return (
    <div className="p-8 w-full grid grid-cols-1 md:grid-cols-2 justify-between gap-4">
      <div className='w-full flex flex-col gap-y-1'>
        <h1 className="text-2xl font-bold text-gray-800">À propos</h1>
        <p className=" text-gray-600">Bienvenue sur notre site web !</p>
        <p className=" text-gray-600 mb-4">Notre mission est d’aider les entrepreneurs et les entreprises à développer les compétences et les connaissances nécessaires pour réussir.</p>
        <h2 className="text-xl font-bold text-gray-800 mb-2">Nos services</h2>
        <ul className="list-disc text-gray-600 mb-4">
          <li>Programmes de formation personnalisés</li>
          <li>Ressources d’apprentissage en ligne</li>
          <li>Ateliers et séminaires</li>
          <li>Conseil en carrière</li>
        </ul>
      </div>
      <div className="w-fit  flex flex-col gap-y-2">

        <h2 className="text-xl font-bold text-gray-800 mb-2">Contactez-nous</h2>
        <div className='flex flex-row gap-x-3 text-sm text-slate-600 justify-start items-center'>
          <FaPhoneAlt />
          <span>033 45 554 23</span>
        </div>
        <div className='flex flex-row gap-x-3 text-sm text-slate-600 justify-start items-center'>
          <FaEnvelope />
          <span>info.haisoa@gmail.com</span>
        </div>
        <div className='flex flex-row gap-x-3 text-sm text-slate-600 justify-start items-center'>
          <FaFacebook />
          <span>HAISOA ASA</span>
        </div>
        <div className='flex flex-row gap-x-3 text-sm text-slate-600 justify-start items-center'>
          <FaLinkedin />
          <span>HAISOA ASA</span>
        </div>
      </div>
    </div>
  );
}