import React from 'react'

const Modal = ({children,width,setShowModal}) => {
    
  return (
    <div className='w-screen h-full fixed top-0 left-0 z-10 bg-gray-950/25 backdrop-blur-sm shadow-md'>
        <div className={"w-["+width+"] bg-white absolute left-1/2 top-1/2 z-20  rounded transform -translate-x-1/2 -translate-y-1/2 "} >
        {/* <button onClick={()=>setShowModal(false)} className='w-[1.7rem] h-[1.7rem] bg-[white] absolute top-[1.6rem] right-[1.6rem] text-[24px] flex items-center rounded justify-center cursor-pointer'>&times;</button>       */}
           {children}
        </div>
    </div>
  )
}

export default Modal