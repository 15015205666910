import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import img from '../../../assets/Logo.png'
import StepperAuth from './StepperAuth';
export default function Auth() {
    const [data, setData] = useState({
        nom: '',
        prenom: '',
        tel: '',
        email: '',
        password: '',
        confirmation_pasword: '',
        photo: null,
        type: 'Entrepreneur',
        code: undefined,
        date_naissance: '',
        sexe: 'Masculin',
        pere: '',
        mere: '',
        adresse: '',
        fax: '',
        niveau: 'Sans formation',
        fonction: 'Propriétaire/Gérant',
        langue_maternelle: '',
        maitrise_langue_francaise: 'Très bien',
        ecrire_dans_cette_langue: 0,
        ecrire_en_francais: 0,
        formGest: 0,
        laquelleFormGest: '',
        dureeFormGest: '',
        expFormGest: '',
        autre_formation_professionnel: '',
        informer_par: 'La television',
        experience_dans_les_affaires: '',
        montant: 0,
        horaire: 'En journée continu',
        
        nom_entreprise: '',
        date_demarrage: '',
        adresse_entreprise: '',
        fax_entreprise: '',
        tel_entreprise: '',
        email_entreprise: '',
        status_juridique: '',
        status_jusidique_autre_preciser: '',
        domaine_activite: '',
        nombre_employee: 0,
        nombre_apprentis: 0,
        nombre_total_personnes: 0,
        description: '',
        marketing_checked: 0,
        marketing_description: '',
        entrepriseFamille_checked: 0,
        entrepriseFamille_description: '',
        approvisionnement_checked: 0,
        approvisionnement_description: '',
       
        achatControleStocks_checked: 0,
        achatControleStocks_description: '',
        estimationCout_checked: 0,
        estimationCout_description: '',
        tenuRegistre_checked: 0,
        tenuRegistre_description: '',
        planifier_checked: 0,
        planifier_description: '',
        personnel_checked: 0,
        personnel_description: '',
        autre_checked: 0,
        autre_description: '',
        pays: '',
        nomJeuneFille: ''
    })
    useEffect(() => {
        console.log(data)
    }, [data])
    const [step, setStep] = useState(0)
    return (
        <div className='flex max-lg:flex-col lg:flex-row max-lg:justify-center max-lg:h-screen lg:justify-between items-center'>
            <div className="w-3/12 lg:h-screen bg-slate-50 flex flex-col justify-center items-center">
                <div className="flex flex-col gap-x-3 items-center gap-y-12">
                    <StepperAuth activeStep={step} />
                    <div className='w-9/12 max-lg:hidden bg-white  shadow-md shadow-slate-500/20 rounded-[4px]'>
                        <img src={img} alt='logo' className="w-[300px]" />
                    </div>
                </div>
            </div>
            <div className='max-md:w-11/12 max-lg:mt-4 max-lg:w-8/12 lg:w-9/12 lg:h-screen bg-white lg:shadow-md lg:shadow-slate-500/40'>
                <Outlet context={{ data, setData, step, setStep }} />
            </div>
        </div>
    )
}