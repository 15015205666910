import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import user1 from '../../../assets/users/prestataire1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import NoResults from '../../components/NoResults'
import loadingImg from '../../../assets/icons/Spinner-2.gif'
import ImageWithFallback from '../../components/ImageWithFallback'


const DetailEntrepreneur = () => {

  const navigate = useNavigate();
  const {id} = useParams()

  // const user_id = 10;
  const user_id = localStorage.getItem('naturagerme-id');

  const [data, setData] = useState([]);
  const [dataPerso, setDataPerso] = useState([]);
  const [dataEntreprise, setDataEntreprise] = useState([]);
  const [dataFormDesire, setDataFormDesire] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null)
  const {setTitle} = useOutletContext()


  useEffect(() => {
    setTitle("Entrepreneur")
    axios.get(api + `/api/getInfoEntrepreneur/${id}`)
      .then(response => {

        setData(response.data);

        if (response.data.donnee_entreprise)
        {
          setDataEntreprise(response.data.donnee_entreprise)
        }
        if (response.data.dataFormDesire)
        {
          setDataFormDesire(response.data.dataFormDesire)
        }
        setDataPerso(response.data.donnee_perso1)
        console.log(data)

        setLoading(false)
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des informations:", error)
        setError("Une erreur est survenue lors du chargement des données.")
        setLoading(false);
      });
  }, [setTitle]);


  // if (loading) {
  //   return <div>
  //     <Header title="Entrepreneur" />
  //     Chargement...</div>;
  // }


  // if (error) {
  //   return <div>{error}</div>;
  // }

  const { entrepreneur, entreprise, formations } = data;

  return (
    <>
      {/* <Header title="Entrepreneur" /> */}
      <div className="border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]">
        <div className="flex items-center gap-2">
          <button className="border rounded-full p-2 w-[35px]" onClick={() => navigate('/formateur/entrepreneur')}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h4 className='text-xl font-semibold'>Détail d'un entrepreneur</h4>
        </div>

        <div className="border p-6 mt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex gap-4">
               <ImageWithFallback src={api + '/storage/' + data?.photo} alt={data?.photo+data?.nom} className='w-12 h-12 rounded-full' defaultType='user'/>
            
              <div className="flex flex-col  gap-2">
                <p className='text-xl text-black-1'>{data.nom}</p>
                <p>{data.email}</p>
              </div>
            </div>
            <div className="md:border-l md:pl-6 border-l-0 pl-0">
              <table className="w-full">
                <tbody className='info'>
                  <tr>
                    <td>Email:</td>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <td>Contact:</td>
                    <td>{dataPerso.tel}</td>
                  </tr>
                  <tr>
                    <td>Adresse:</td>
                    <td>{dataPerso.adressePostale}</td>
                  </tr>
                  <tr>
                    <td>Fonction:</td>
                    <td>{dataPerso.fonctionEntreprise}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="border p-6 mt-6">
          <h4 className='ml-4 text-xl font-semibold'>Information sur l'entreprise</h4>
          <div className="mt-4">
            <table className="w-full">
              <tbody className='info'>
                <tr>
                  <td>Nom de l'entreprise:</td>
                  <td>{dataEntreprise.nom}</td>
                </tr>
                <tr>
                  <td>Contact:</td>
                  <td>{dataEntreprise.tel}</td>
                </tr>
                <tr>
                  <td>Adresse postale:</td>
                  <td>{dataEntreprise.adressePostale}</td>
                </tr>
                <tr>
                  <td>Activité:</td>
                  <td>{dataEntreprise.activiteEntreprise}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="border p-6 mt-6">
          <h4>Formation désirée</h4>
          <div className="mt-4">
            {formations.length > 0 ? (
              formations.map((formation, index) => (
                <div key={index} className="border-b py-2">
                  <p>{formation}</p>
                </div>
              ))
            ) : (
              <p>Aucune formation désirée pour le moment.</p>
            )}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default DetailEntrepreneur;
