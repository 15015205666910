import React, { useEffect, useState } from 'react';
import img from '../../../assets/black.jpg'
import CardFormation from '../../components/card_formation';
import Button from '../../components/btn/Button';
import Input from '../../components/form/Input';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api';
import axios from 'axios';
import { Skeleton } from '@mui/material';

export default function Formation() {
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const [data, setData] = useState([
    ]);

    function go(item) {

        const id = localStorage.getItem('naturagerme-id')
        if (id) {
            navigate("/ABF/" + item)
        } else {
            navigate("/Login")
        }
    }

    useEffect(() => {
        // Remplacer cette URL par l'URL de votre API Laravel
        axios.get(api + '/api/getAllFormations')
            .then(response => {
                setData(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des formations:", error);
            });
    }, []);

    return (
        <>
            <div className='w-11/12 flex max-lg:flex-col lg:flex-row justify-between max-lg:items-start lg:items-end '>
                <span className='text-[#2C2F24] font-bold text-2xl'>Formation(s)</span>
                <div className='w-[300px] flex flex-row justify-end items-end  gap-x-3'>
                    <Input placeholder={"Recherche par nom du formation"} value={search} onChange={(e) => { setSearch(e.target.value) }} />
                    {/* <Button title={"Rechercher"}    /> */}
                </div>
            </div>
            <div className='w-11/12 flex flex-row flex-wrap justify-start items-center gap-x-20'>
                {
                    data.length !== 0 ? (

                        data.filter((item) => 
                            search === '' || item.titre.toLowerCase().includes(search.toLowerCase())
                        ).map((item, index) => (
                            <button key={index} className='w-[250px]' onClick={() => { go(item.id) }}>
                                <CardFormation item={item} />
                            </button>
                        ))

                    ) : (
                        <>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                           


                        </>

                    )
                }
            </div>
        </>
    )
}