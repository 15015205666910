import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import user1 from '../../../assets/users/prestataire1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import Fiche from '../Rapport/Fiche'
import Modal from '../../components/Modals'
import Notification from '../../../entrepreneur/components/feedback/Notification'
import Modals from '../../../entrepreneur/components/modal'
import ImageWithFallback from '../../components/ImageWithFallback'

export const DetailInscription = () => {
  const navigate = useNavigate()

  const {id}= useParams() //ID INSCRIPTION
  const [dataUser, setDataUser] = useState([])
  const [dataPerso1, setDataPerso1] = useState([])
  const [dataEntreprise, setDataEntreprise] = useState([])
  const [dataAbf1, setDataAbf1] = useState([])
  const [dataAbf2, setDataAbf2] = useState([])
  const [dataAbf3, setDataAbf3] = useState([])
  const [dataFormation, setdataFormation] = useState([])
  const {setTitle} = useOutletContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [option, setoption] = useState("Accepter")

  const [isOpen, setIsOpen] = useState({
    message: '',
    success: false,
    fails: false
})

  const approuve = () => {
    
    axios.post(api + `/api/confirmerInscription/${id}`)
    .then(response =>{
      setIsModalOpen(false)
      setIsOpen({...isOpen, success: true , message: "Inscription Acceptée!"})
// feedback
    })
    .catch(error => {
      setIsModalOpen(false)
      setIsOpen({...isOpen, fails: true , message:"Une erreur est survenue !"})
    })
  }
  const deny = () => {
    
    axios.post(api + `/api/refuserInscription/${id}`)
    .then(response =>{
      setIsModalOpen(false)
      setIsOpen({...isOpen, success: true , message: "Inscription Réfusée!"})
    })
    .catch(error => {
      setIsModalOpen(false)
      setIsOpen({...isOpen, fails: true , message:"Une erreur est survenue !"})
    })
  }

  const getInfo1 = () => {

    axios.get(api + `/api/detailInscriptionPerso1/${id}`)
    .then(response =>{

      if (response.data.user)
      {
        setDataUser(response.data.user);
      }

      if (response.data.user.donnee_perso1)
      {
        setDataPerso1(response.data.user.donnee_perso1)
      }

      if (response.data.user.donnee_entreprise)
      {
        setDataEntreprise(response.data.user.donnee_entreprise)
      }

    })
    .catch(error => {
      
    })
  }

  const getAbf1 = () => {

    axios.get(api + `/api/detailInscriptionAbf1/${id}`)
    .then(response =>{

      if (response.data)
      {
        console.log("detailInscriptionAbf1 "+response.data)
        setDataAbf1(response.data);
      }
    })
    .catch(error => {
      
    })
  }

  const getFormation = () => {

    axios.get(api + `/api/detailInscriptionFormation/${id}`)
    .then(response =>{

      if (response.data.formation)
      {
        setdataFormation(response.data.formation);
      }
    })
    .catch(error => {
      
    })
  }

  const getAbf2 = () => {

    axios.get(api + `/api/detailInscriptionAbf2/${id}`)
    .then(response =>{

      if (response.data)
      {
        setDataAbf2(response.data);
      }

      // console.log(response.data.competences);
      // console.log(response.data.competences)
    })
    .catch(error => {
      
    })
  }

  const getAbf3 = () => {

    axios.get(api + `/api/detailInscriptionAbf3/${id}`)
    .then(response =>{

      if (response.data[0])
      {
        setDataAbf3(response.data[0]);
        // console.log(response.data)
      }
    })
    .catch(error => {
      
    })
  }

  useEffect(() => {
    setTitle('Inscription')
    getInfo1()
    getAbf1()
    getAbf2()
    getAbf3()
    getFormation()

  }, [setTitle])

  const formatDate = (date) => {
    if (!date) return ''; // Vérifie que la date est définie
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
};

  // const imageUrl = dataUser.photo ? api + '/storage/' + dataUser.photo : user1;

  const guessFormation = (points) => {
    switch (true) {
      case points>=0 && points<=51:
        return 'SFE (Séminaire de Formation d’Entrepreneur)'
      case points>=52 && points<=62:
        return 'GAE (Groupe d’Amélioration des Entreprises)'
      case points>=63 && points<=72:
        return 'EI (Encadrement Individuel)'
    }
  }

  const confirmModification = () => {
    if (option === 'Accepter') {
      approuve()
    } else if (option === 'Réfuser') {
      deny()
    }
  }

  const cancelModification = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (opt) => {
    setoption(opt);
    setIsModalOpen(true);
  }

  return (
    <>
    {/* <Header title="Détail d'une inscription"/> */}
    <div className="border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <button className={"  border  rounded-full p-2 w-[34px]"} onClick={()=>navigate('/formateur/inscription')} >
            <FontAwesomeIcon icon={faArrowLeft}/>
          </button>
          <h4 className='text-xl font-semibold'>Détail sur une inscription</h4>
      </div>
      <div className='flex gap-3 items-center'>
           {/* <button className='bg-gray-50  px-6 py-2 rounded-md '>Imprimer une fiche</button> */}
           <Fiche id={id}/>
           
           <button
           onClick={()=>{
            handleSubmit("Accepter")
            // approuve()
            // setisOpen({...isOpen, show: true})
          }}
           className='bg-[#E4FFE2] text-[#7EDF76] px-6 py-2 rounded-md hover:bg-[#7ce373]/20'>Accepter</button>
           <button 
            onClick={()=>handleSubmit("Réfuser")}
           className='bg-[#FFE9E9] text-[#FF4F4F] px-6 py-2 rounded-md hover:bg-[#ffbebe]/20'>Réfuser</button>
        </div>
      </div>
        
    <div className='border-line mr-4 p-6 mt-6'>
      <div className="grid grid-cols-1 md:grid-cols-2 space-y-4">
        <div>
          <div className="flex gap-4">
          <ImageWithFallback src={api + '/storage/' + dataUser?.photo} alt="User" className='w-16 h-16 rounded-full' defaultType='user'/>
        <div className="flex flex-col justify-between gap-2 items-start" >

          <p className='text-xl text-black-1 font-semibold'>{dataUser?.nom}</p>
          <p>{dataUser.email}</p>
          

        </div>
          </div>
          {/* <p className='mt-4 text-start'>Lorem ipsum dolor sit amet consectetur adipisicing elit. At, quos, dolorum sequi ea repellendus iure sed odio quaerat quidem vel illo quod incidunt sapiente esse, optio ex. In, omnis ipsa.</p> */}
        </div>
        <div className=' border-none md:border-l px-0 md:px-6'>
          <table className='w-[100%]'>
          <tbody className='info'>
            <tr >
              <td>Email:</td>
              <td>{dataUser.email}</td>
            </tr>
            <tr>
              <td>Contact:</td>
              <td>{dataPerso1?.tel}</td>
            </tr>
            <tr>
              <td>Adresse postale:</td>
              <td>{dataPerso1?.adressePostale}</td>
            </tr>
            <tr>
              <td>Profession:</td>
              <td>{dataPerso1?.fonctionEntreprise}</td>
            </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>

    {/* section  détail de la formation*/}
    <div className='border-line mr-4 p-6 mt-6'>
    <h4 className='ml-4 text-xl font-semibold'>Détails de la formation</h4>
      <div className="grid grid-cols-2">
      <table className='w-[100%]'>
          <tbody className='info'>
            <tr>
              <td>Titre:</td>
              <td>{dataFormation.titre}</td>
            </tr>
            <tr>
              <td>Durée:</td>
              <td>{dataFormation.duree}</td>
            </tr>
            <tr>
              <td>Date de début:</td>
              <td>{formatDate(dataFormation.dateDebut)}</td>
            </tr>
            <tr>
              <td>Date de fin:</td>
              <td>{formatDate(dataFormation.dateFin)}</td>
            </tr>
          </tbody>
          </table>
        
        <div className='border-l px-6'>
          {/* <div> */}
          <table className='w-[100%]'>
          <tbody className='info '>
            <tr>
              <td className=''>Description:</td>
              <td> {dataFormation.description}</td>
              {/* {dataFormation.length} */}
            </tr>
            </tbody>
            </table>
        {/* </div> */}

        </div>
      </div>
    </div>

{(dataFormation.length !== 0) &&  
<div className='border-line mr-4 p-6 mt-6'>
<h4 className='ml-5 mb-2 text-xl font-semibold'>Résumé de l'analyse des besoins</h4>
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
  <div className="overflow-hidden">
  <table className="min-w-full text-left text-sm font-light text-surface dark:text-white border rounded-xl mb-4">
  <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
    <tr>
      <th className="px-6 py-4">No</th>
      <th className="px-6 py-4">Labelle</th>
      <th className="px-6 py-4">Point</th>
      <th className="px-6 py-4">Activité recomandé</th>
    </tr>
  </thead>
  <tbody>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
      <td className="flex px-6 py-4 font-medium gap-4">
        1
      </td>
      <td>Indicateurs des compétences en gestion et analyse des besoins en formation</td>
      <td>{dataAbf1}</td>
      <td>{guessFormation(dataAbf1)}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
      <td className="flex px-6 py-4 font-medium gap-4">
        2
      </td>
      <td>MANUELS GERME EN POSSESSION DE L’ENTREPRENEUR</td>
      <td>{dataAbf2}</td>
      <td>{guessFormation(dataAbf2)}</td>
    </tr>
  </tbody>
</table>
{/* tableau sur les programmes de formation et partage de coût  */}
<table className="min-w-full text-left text-sm font-light text-surface dark:text-white border rounded-xl mb-4">
  <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
    <tr>
      <th className="px-6 py-4">No</th>
      <th className="px-6 py-4">Labelle</th>
      <th className="px-6 py-4">Paiement avant la formation (en AR)</th>
      <th className="px-6 py-4">Date de participation</th>
      <th className="px-6 py-4">Montant payé</th>
      <th className="px-6 py-4">Pourcentage de coût (%)</th>
    </tr>
  </thead>
  <tbody>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
      <td className="flex px-6 py-4 font-medium gap-4">
        1
      </td>
      <td>Séminaire de Formation d’entrepreneurs</td>
      <td>{dataAbf3?.form1Montant1}</td>
      <td>{formatDate(dataAbf3?.form1Date)}</td>
      <td>{dataAbf3?.form1Montant2}</td>
      <td>{dataAbf3?.form1Pourcentage}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
    <td className="flex px-6 py-4 font-medium gap-4">
        2
      </td>
      <td>Séminaire de recyclage</td>
      <td>{dataAbf3?.form2Montant1}</td>
      <td>{formatDate(dataAbf3?.form2Date)}</td>
      <td>{dataAbf3?.form2Montant2}</td>
      <td>{dataAbf3?.form2Pourcentage}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
    <td className="flex px-6 py-4 font-medium gap-4">
        3
      </td>
      <td>Session de groupe d’amélioration des entreprises (1)</td>
      <td>{dataAbf3?.form3Montant1}</td>
      <td>{formatDate(dataAbf3?.form3Date)}</td>
      <td>{dataAbf3?.form3Montant2}</td>
      <td>{dataAbf3?.form3Pourcentage}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
    <td className="flex px-6 py-4 font-medium gap-4">
        5
      </td>
      <td>Session de groupe d’amélioration des entreprises (2)</td>
      <td>{dataAbf3?.form4Montant1}</td>
      <td>{formatDate(dataAbf3?.form4Date)}</td>
      <td>{dataAbf3?.form4Montant2}</td>
      <td>{dataAbf3?.form4Pourcentage}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
    <td className="flex px-6 py-4 font-medium gap-4">
        6
      </td>
      <td>Session de groupe d’amélioration des entreprises (3)</td>
      <td>{dataAbf3?.form5Montant1}</td>
      <td>{formatDate(dataAbf3?.form5Date)}</td>
      <td>{dataAbf3?.form5Montant2}</td>
      <td>{dataAbf3?.form5Pourcentage}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
    <td className="flex px-6 py-4 font-medium gap-4">
        7
      </td>
      <td>Session d’encadrement Individuel (1)</td>
      <td>{dataAbf3?.form6Montant1}</td>
      <td>{formatDate(dataAbf3?.form6Date)}</td>
      <td>{dataAbf3?.form6Montant2}</td>
      <td>{dataAbf3?.form6Pourcentage}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
    <td className="flex px-6 py-4 font-medium gap-4">
        8
      </td>
      <td>Session d’encadrement Individuel (2)</td>
      <td>{dataAbf3?.form7Montant1}</td>
      <td>{formatDate(dataAbf3?.form7Date)}</td>
      <td>{dataAbf3?.form7Montant2}</td>
      <td>{dataAbf3?.form7Pourcentage}</td>
    </tr>
    <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
    <td className="flex px-6 py-4 font-medium gap-4">
        9
      </td>
      <td>Session d’encadrement Individuel (3)</td>
      <td>{dataAbf3?.form8Montant1}</td>
      <td>{formatDate(dataAbf3?.form8Date)}</td>
      <td>{dataAbf3?.form8Montant2}</td>
      <td>{dataAbf3?.form8Pourcentage}</td>
    </tr>
  </tbody>
</table>

  </div>
</div>
</div>
    </div>}
   
    </div>

<Modals 
        isOpen={isModalOpen}
        onRequestClose={cancelModification}>
            <div className="sm:flex sm:items-start">
                <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium" id="modal-headline">
                        Confirmation
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Voulez-vous enregistrer vos modifications ?
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                // onClick={handleSubmit}
                onClick={confirmModification}
                type="button" data-behavior="commit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Enregister
                </button>
                <button
                onClick={cancelModification}
                type="button" data-behavior="cancel" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-50 sm:mt-0 sm:w-auto sm:text-sm">
                    Annuler
                </button>
            </div>
            </Modals>
   <Notification type="success" message={isOpen.message} isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />
   <Notification type="error" message={isOpen.message} isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

    </>
  )
}
