import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function List(index) {
    const location = useLocation();
    const navigate = useNavigate();

    const pathName = [
        {
            name: 'Accueil',
            path: '/',
        },
        {
            name: 'Formation',
            path: '/Formation',
        },
        {
            name: 'Profil',
            path: '/Profile',

        },
        // {
        //     name: 'A propos',
        //     path: '/apropos',
        // },
        // {
        //     name: 'Contact',
        //     path: '/Contact',
        // },
    ];

    useEffect(() => {
        console.log(typeof location.pathname);
    }, []);

    return (
        <ul className="flex flex-row justify-end items-start gap-x-4">
            {
                pathName.map((item, index) => (
                    item.name == "Profil" ? (
                        localStorage.getItem('naturagerme-id') && (
                            <button
                                key={index}
                                onClick={() => { navigate(item.path) }}
                                className={`${location.pathname === item.path ? 'border-b-2 border-yellow-300 hover:cursor-pointer text-lg rounded-none' : ''} font-semibold text-lg`}
                            >
                                {item.name}
                            </button>
                        )
                    ) : (
                        <button
                            key={index}
                            onClick={() => { navigate(item.path) }}
                            className={`${location.pathname === item.path ? 'border-b-2 border-yellow-300 hover:cursor-pointer text-lg rounded-none' : ''} font-semibold text-lg`}
                        >
                            {item.name}
                        </button>
                    )
                ))
            }
        </ul>
    );
}