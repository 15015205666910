import React, { useEffect, useState } from 'react';
import Card from '../../components/Card';
import img from '../../../assets/profile.png'
import { IoCalendarOutline, IoLocationOutline, IoMailOutline, IoMaleOutline, IoPencilOutline, IoPersonCircle, IoPersonCircleOutline } from 'react-icons/io5'
import { FaPenSquare } from 'react-icons/fa';
import axios from 'axios';
import { api } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
export default function Profile() {
    const [data, setData] = useState({})
    const navigate = useNavigate()
    const [perso1, setPerso1] = useState()
    const [perso2, setPerso2] = useState()
    const [entreprise, setEntreprise] = useState()
    // const [domaine,setDomaine]=useState()
    // const [user_id,setUser_id]=useState()
    function fetchData() {
        try {
            axios.get(api + `/api/getInfoEntrepreneur/${localStorage.getItem('naturagerme-id')}`)
                .then(res => {
                    console.log(res.data)
                    setData(res.data)
                    setPerso1(res.data?.donnee_perso1)
                    setPerso2(res.data?.donnee_perso2)
                    setEntreprise(res.data?.donnee_entreprise)
                })
                .catch(err => {
                    console.error(err)
                })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchData();
        if (!localStorage.getItem('naturagerme-id')) {
            // localStorage.getItem('naturagerme-id')
            // fetchData()
            navigate('/Login')
            // console.log(localStorage.getItem('naturagerme-id'));

        }
    }, [])
    return (
        <div className='w-screen flex flex-row justify-center items-start bg-slate-50 py-5'>
            <div className='w-11/12 flex  flex-col-reverse justify-start items-start  gap-2'>
                {
                    data?.role !== "Formateur" && (
                        <div className='w-full  card bg-white p-3 flex flex-col gap-y-7'>
                            <div className='w-full flex flex-row justify-between items-start'>
                                <div className='flex flex-col gap-y-2'>
                                    <span className='text-[#11141A] font-semibold text-3xl'>Formulaire de candidature</span>
                                    {/* <span className='text-[#9F9F9F]'>Samedi, 3 Août 2023 à 5:00</span> */}
                                </div>
                                <button className=''>
                                    {/* <FaPenSquare className='text-xl text[#11141A]' /> */}
                                </button>
                            </div>
                            <div className='flex flex-col gap-y-4'>
                                <span className='font-semibold  text-[#101928] text-xl'>
                                    Données personnels
                                </span>
                                <div className='grid max-md:grid-cols-2  max-lg:grid-cols-3 lg:grid-cols-4 w-full gap-x-[112px] gap-y-6'>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Niveau de formation
                                        </span>
                                        <span className="  text-[#101928]">
                                            {perso1?.niveauFormation}
                                        </span>
                                    </div>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Fonction dans l'entreprise
                                        </span>
                                        <span className="  text-[#101928]">
                                            {perso1?.fonctionEntreprise}
                                        </span>
                                    </div>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Langue maternelle
                                        </span>
                                        <span className="  text-[#101928]">
                                            {perso2?.langueMaternelle}
                                        </span>
                                    </div>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Experience
                                        </span>
                                        <span className="  text-[#101928]">
                                            {perso2?.experienceAffaire}
                                        </span>
                                    </div>

                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Horaire
                                        </span>
                                        <span className="  text-[#101928]">
                                            {perso2?.horaireFormation}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-4'>
                                <span className='font-semibold text-xl text-[#101928]'>
                                    Donnees relative à l’entreprise
                                </span>
                                <div className='grid max-md:grid-cols-2  max-lg:grid-cols-3 lg:grid-cols-4 w-full gap-x-[112px] gap-y-6'>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Raison social
                                        </span>
                                        <span className="  text-[#101928]">
                                            {entreprise?.nom}
                                        </span>
                                    </div>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Date de demarrage
                                        </span>
                                        <span className="  text-[#101928]">
                                            {entreprise?.dateDemarrage}
                                        </span>
                                    </div>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Status
                                        </span>
                                        <span className="  text-[#101928]">
                                            {entreprise?.statutJuridique}
                                        </span>
                                    </div>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Nombre employées
                                        </span>
                                        <span className="  text-[#101928]">
                                            {entreprise?.nombreEmploye}
                                        </span>
                                    </div>

                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Nombre de personnes au total
                                        </span>
                                        <span className="  text-[#101928]">
                                            {entreprise?.nombrePersoTotal}
                                        </span>
                                    </div>
                                    <div className='flex-col flex justify-start items-start '>
                                        <span className=" font-bold text-[#101928]">
                                            Domaine
                                        </span>
                                        <span className="  text-[#101928]">
                                            {entreprise?.domaine}
                                        </span>
                                    </div>


                                </div>

                            </div>
                        </div>
                    )
                }
                <div className='w-full  card bg-white  flex flex-row gap-y-2'>

                    {
                        data?.photo ? (
                            <img src={api + '/storage/' + data?.photo} alt='profile' className='w-[300px]' />
                        ) :
                            (

                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />

                            )
                    }
                    <div className='flex flex-col gap-y-1 p-4'>
                        <span className='text-[#202020] font-bold text-lg'>{data?.role}</span>
                        <div className='flex flex-row gap-x-3 justify-start items-center text-[#6B7280]' >
                            <IoPersonCircleOutline />
                            <span >{data.nom}</span>
                        </div>
                        <div className='flex flex-row gap-x-3 justify-start items-center text-[#6B7280]'>
                            <IoMailOutline />
                            <span>{data.email}</span>
                        </div>
                        {/* <div className='flex flex-row gap-x-3 justify-start items-center text-[#6B7280]'>
                            <IoCalendarOutline />
                            <span>Randrianjafy Maurice</span>
                        </div> */}
                        {/* <div className='flex flex-row gap-x-3 justify-start items-center text-[#6B7280]'>
                            <IoMaleOutline />
                            <span>Randrianjafy Maurice</span>
                        </div> */}
                        {
                            data?.pays && (
                                <div className='flex flex-row gap-x-3 justify-start items-center text-[#6B7280]'>
                                    <IoLocationOutline />
                                    <span>{perso1?.pays}</span>
                                </div>
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}