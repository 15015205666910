import React from "react";
import Input from '../../../components/form/Input'
import { useOutletContext } from "react-router-dom";

export default function Programme() {

    const { data, setData } = useOutletContext()

    return (
        <div className="w-full flex flex-col justify-start items-start gap-y-4">
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="title w-full text-left">Formations Germe</span>
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Séminaire de Formation d’entrepreneurs
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input type="number" required title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.form1Montant1} onChange={(e) => { setData({ ...data, form1Montant1: e.target.value }) }} />
                    <Input type="date" required title={"Date de participation"} value={data.form1Date} onChange={(e) => { setData({ ...data, form1Date: e.target.value }) }} />
                    <Input type="number" required  title={"Montant payé par l’entrepreneur"} value={data.form1Montant2} onChange={(e) => { setData({ ...data, form1Montant2: e.target.value }) }} />
                </div>
            </div>



            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Séminaire de recyclage
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input required title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.form2Montant1} onChange={(e) => { setData({ ...data, form2Montant1: e.target.value }) }} type="number" />
                    <Input required type="date" title={"Date de participation"} value={data.form2Date} onChange={(e) => { setData({ ...data, form2Date: e.target.value }) }} />
                    <Input required title={"Montant payé par l’entrepreneur"} value={data.form2Montant2} onChange={(e) => { setData({ ...data, form2Montant2: e.target.value }) }} type="number" />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session de groupe d’amélioration des entreprises (1)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input required type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.form3Montant1} onChange={(e) => { setData({ ...data, form3Montant1: e.target.value }) }} />
                    <Input required type="date" title={"Date de participation"} value={data.form3Date} onChange={(e) => { setData({ ...data, form3Date: e.target.value }) }} />
                    <Input required type="number" title={"Montant payé par l’entrepreneur"} value={data.form3Montant2} onChange={(e) => { setData({ ...data, form3Montant2: e.target.value }) }} />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session de groupe d’amélioration des entreprises (2)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input required type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.form4Montant1} onChange={(e) => { setData({ ...data, form4Montant1: e.target.value }) }} />
                    <Input required type="date" title={"Date de participation"} value={data.form4Date} onChange={(e) => { setData({ ...data, form4Date: e.target.value }) }} />
                    <Input required type="number" title={"Montant payé par l’entrepreneur"} value={data.form4Montant2} onChange={(e) => { setData({ ...data, form4Montant2: e.target.value }) }} />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session de groupe d’amélioration des entreprises (3)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input required type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.form5Montant1} onChange={(e) => { setData({ ...data, form5Montant1: e.target.value }) }} />
                    <Input required type="date" title={"Date de participation"} value={data.form5Date} onChange={(e) => { setData({ ...data, form5Date: e.target.value }) }} />
                    <Input required type="number" title={"Montant payé par l’entrepreneur"} value={data.form5Montant2} onChange={(e) => { setData({ ...data, form5Montant2: e.target.value }) }} />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session d’encadrement Individuel (1)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">
                    <Input required type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.form6Montant1} onChange={(e) => { setData({ ...data, form6Montant1: e.target.value }) }} />
                    <Input required type="date" title={"Date de participation"} value={data.form6Date} onChange={(e) => { setData({ ...data, form6Date: e.target.value }) }} />
                    <Input required type="number" title={"Montant payé par l’entrepreneur"} value={data.form6Montant2} onChange={(e) => { setData({ ...data, form6Montant2: e.target.value }) }} />
                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session d’encadrement Individuel (2)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full" >
                    <Input required type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.form7Montant1} onChange={(e) => { setData({ ...data, form7Montant1: e.target.value }) }} />
                    <Input required type="date" title={"Date de participation"} value={data.form7Date} onChange={(e) => { setData({ ...data, form7Date: e.target.value }) }} />
                    <Input required type="number" title={"Montant payé par l’entrepreneur"} value={data.form7Montant2} onChange={(e) => { setData({ ...data, form7Montant2: e.target.value }) }} />
                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session d’encadrement Individuel (3)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">
                    <Input required type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"}  value={data.form8Montant1} onChange={(e) => { setData({ ...data, form8Montant1: e.target.value }) }} />
                    <Input required type="date" title={"Date de participation"} value={data.form8Date} onChange={(e) => { setData({ ...data, form8Date: e.target.value }) }} />
                    <Input required type="number" title={"Montant payé par l’entrepreneur"} value={data.form8Montant2} onChange={(e) => { setData({ ...data, form8Montant2: e.target.value }) }} />
                </div>
            </div>

        </div>
    )
}