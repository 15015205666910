import React, { useEffect, useState } from 'react';
// import Input from '../../entrepreneur/components/form/Input';
import Select from '../../entrepreneur/components/form/select';
// import Button from '../../entrepreneur/components/btn/Button';

import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import { api } from '../../api';
import Load from '../../entrepreneur/components/load';
import Notification from '../../entrepreneur/components/feedback/Notification';
import ToLogin from '../../entrepreneur/components/toLogin';

export default function Desire() {
    const { data, setData } = useOutletContext()
    const [options, setOptions] = useState({
        loading: false,
        disabled: false
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
    })
    const navigation = useNavigate()
    async function go() {
        setOptions({ ...options, loading: true })
        try {
            const form4 = new FormData()
            form4.append("marketing", data.marketing_checked)
            form4.append("exp1", data.marketing_description)
            form4.append("achatControleStock", data.achatControleStocks_checked)
            form4.append("exp2", data.achatControleStocks_description)
            form4.append("estimationCout", data.estimationCout_checked)
            form4.append("exp3", data.estimationCout_description)
            form4.append("tenueRegistre", data.tenuRegistre_checked)
            form4.append("exp4", data.tenuRegistre_description)
            form4.append("planifierEntreprise", data.planifier_checked)
            form4.append("exp5", data.planifier_description)
            form4.append("persoProd", data.personnel_checked)
            form4.append("exp6", data.personnel_description)
            form4.append("autre", data.autre_checked)
            form4.append("exp7", data.autre_description)
            form4.append("user_id", localStorage.getItem("naturagerme-id"))

            form4.append("entrepriseFamille", data.entrepriseFamille_checked)
            form4.append("exp8", data.entrepriseFamille_description)
            form4.append("approvisionnement", data.approvisionnement_checked)
            form4.append("exp9", data.approvisionnement_description)

            await axios.post(api + "/api/storeFormationDesire", form4)
                .then(res => {
                    console.log(res.data)
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, success: true })
                    navigation('/Login')

                })
                .catch(err => {
                    console.error(err)
                    setIsOpen({ ...isOpen, fails: true })
                    setOptions({ ...options, loading: false })

                })
        } catch (error) {
            console.error(error)
            setIsOpen({ ...isOpen, fails: true })
            setOptions({ ...options, loading: false })

        }
    }

    return (
        <form onSubmit={(e) => { e.preventDefault(); go() }} className="w-11/12 flex flex-col gap-y-2">
            <span className="font-black text-3xl">
                Formations désirées
            </span>
            <div className='flex flex-col gap-y-3 items-start justify-start'>
                <span className='font-semibold mb-4'>
                    Dans quel(s) domaine(s) désirez-vous être formé ?  Expliquez pourquoi :
                </span>
                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.marketing_checked} onClick={(e) => { setData({ ...data, marketing_checked: e.target.value?1:0 }); console.log(e.target.checked) }} />
                        <label className='label_input'>Marketing</label>
                    </div>

                    {
                        data.marketing_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.marketing_description} onChange={(e) => { setData({ ...data, marketing_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>

                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.achatControleStocks_checked} onClick={(e) => { setData({ ...data, achatControleStocks_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'> Achat et contrôle des stocks</label>
                    </div>

                    {
                        data.achatControleStocks_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.achatControleStocks_description} onChange={(e) => { setData({ ...data, achatControleStocks_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>
                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.estimationCout_checked} onClick={(e) => { setData({ ...data, estimationCout_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'> Estimation des coûts</label>
                    </div>

                    {
                        data.estimationCout_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.estimationCout_description} onChange={(e) => { setData({ ...data, estimationCout_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>
                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.tenuRegistre_checked} onClick={(e) => { setData({ ...data, tenuRegistre_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'>La tenue des registres</label>
                    </div>

                    {
                        data.tenuRegistre_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.tenuRegistre_description} onChange={(e) => { setData({ ...data, tenuRegistre_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>
                <div className='w-full flex flex-col gap-y-1'>

                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.planifier_checked} onClick={(e) => { setData({ ...data, planifier_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'>Planifier pour votre entreprise</label>
                    </div>

                    {
                        data.planifier_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.planifier_description} onChange={(e) => { setData({ ...data, planifier_description: e.target.value }) }} />
                            </>
                        )
                    }

                </div>
                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.personnel_checked} onClick={(e) => { setData({ ...data, personnel_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'> Personnel et productivité</label>
                    </div>

                    {
                        data.personnel_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.personnel_description} onChange={(e) => { setData({ ...data, personnel_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>
                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.entrepriseFamille_checked} onClick={(e) => { setData({ ...data, entrepriseFamille_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'> Entreprise et famille</label>
                    </div>

                    {
                        data.entrepriseFamille_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.entrepriseFamille_description} onChange={(e) => { setData({ ...data, entrepriseFamille_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>
                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.approvisionnement_checked} onClick={(e) => { setData({ ...data, approvisionnement_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'> Approvisionnement </label>
                    </div>

                    {
                        data.approvisionnement_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.approvisionnement_description} onChange={(e) => { setData({ ...data, approvisionnement_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>
                <div className='w-full flex flex-col gap-y-1'>
                    <div className="flex flex-row  gap-x-2 justify-start items-center">
                        <input type="checkbox" className='' value={data.autre_checked} onClick={(e) => { setData({ ...data, autre_checked: e.target.value?1:0  }); console.log(e.target.checked) }} />
                        <label className='label_input'>Autres </label>
                    </div>
                    {
                        data.autre_checked == true && (
                            <>
                                <label className='label_input'>Pourquoi ?</label>
                                <input type='text' className='input w-full' value={data.autre_description} onChange={(e) => { setData({ ...data, autre_description: e.target.value }) }} />
                            </>
                        )
                    }
                </div>
            </div>
            <div className="w-full flex max-lg:flex-col-reverse gap-y-2 justify-between item-center">
                <ToLogin />

                <Button size="small" disabled={options.disabled} sx={{
                    bgcolor: "#1e293b",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#1e293b", // Change the hover background color
                        color: "white", // Change the hover text color
                    },
                }} variant='contained' className="px-9 " type="submit">
                    <Load title={"Terminer"} loading={options.loading} />
                </Button>
            </div>
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
            <Notification type="success" message="Inscription éfféctuée." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />

        </form>
    )
}