import React, { useEffect, useState } from 'react'
import user1 from '../../../assets/images/icons8-test-account-96.png' // Placeholder image
import { useNavigate, useOutletContext } from 'react-router-dom'
import file from '../../../assets/icons/file-o.svg'
import megaphone from '../../../assets/icons/megaphone-svgrepo-com.svg'
import cup from '../../../assets/icons/folder-svgrepo-com.svg'
import notebook from '../../../assets/icons/notebook-svgrepo-com.svg'
import flag from '../../../assets/icons/flat-flag-svgrepo-com.svg'
import file1 from '../../../assets/icons/file-svgrepo-com.svg'
import Status from '../../components/Status'
import Header from '../../components/Header'
import ResumeCard from '../../components/ResumeCard'
import axios from 'axios'
import { api } from '../../../api'
import ImageWithFallback from '../../components/ImageWithFallback'


const Dashboard = () => {
  const navigate = useNavigate()
  const [resume, setResume] = useState([
    { image: notebook, title: "Entrepreneur", statistic: 10, number:0},
    { image: flag, title: "Formation", statistic: 10, number: 0 },
    { image: file1, title: "Inscription confirmé", statistic: 12, number: 0 },
    { image: cup, title: "Inscription en attente", statistic: 12, number: 0 }
  ])
  // const user_id = 10
  const user_id = localStorage.getItem('naturagerme-id');
  const {setTitle} = useOutletContext()
// const resumeContent = [
// {
//   image: notebook ,
//   title: "Entrepreneur",
//   statistic:12,
//   number: 1
// },
// {
//   image:flag,
//   title: "Formation",
//   statistic:12,
//   number: 2
// },
// {
//   image: file1,
//   title: "Inscription confirmé",
//   statistic:12,
//   number: 1
// },
// {

//   image:cup ,
//   title: "Inscription en attente",
//   statistic:12,
//   number: 3
// }
// ]

const getNombreEntrepreneur = () => {
  axios.get(api+`/api/nombreEntrepreneur`)
    .then(response => {
      console.log(response.data)
    })
    .catch(error => {
      console.error("Erreur lors de la récupération des formations:", error)
    })
}

const getNombreFormation = () => {
  axios.get(api+`/api/getNombreFormation}`)
    .then(response => {
      console.log(response.data)
    })
    .catch(error => {
      console.error("Erreur lors de la récupération des formations:", error)
    })
}

const [inscriptions, setInscriptions] = useState([]);

const getListeInscriptionsFormateur = () => {
  axios.get(api + `/api/listeInscriptionsFormateur/${user_id}`)
    .then(response => {
      setInscriptions(response.data);
    })
    .catch(error => {
      console.error("Il y a eu une erreur lors de la récupération des inscriptions!", error);
    });
};


const fetchData = async () => {
  try {
    const entrepreneurResponse = await axios.get(`${api}/api/getNombreEntrepreneur`);
    const formationResponse = await axios.get(`${api}/api/getNombreFormation`);
    const inscriptionsConfResponse = await axios.get(`${api}/api/getNombreInscriptionConf/${user_id}`);
    const inscriptionsEnAttResponse = await axios.get(`${api}/api/getNombreInscriptionAtt/${user_id}`);

    setResume([
      { image: notebook, title: "Entrepreneur", statistic: 10, number: entrepreneurResponse.data},
      { image: flag, title: "Formation", statistic: 10, number: formationResponse.data },
      { image: file1, title: "Inscription confirmé", statistic: 12, number: inscriptionsConfResponse.data },
      { image: cup, title: "Inscription en attente", statistic: 12, number: inscriptionsEnAttResponse.data }
    ])

    // setInscriptions(inscriptionsResponse.data);
    // setLoading(false);
  } catch (err) {
    // setError(err.message);
    // setLoading(false);
  }
};

useEffect(() => {
  setTitle('Tableau de bord')
  // const entrepreneurResponse = await axios.get(`${api}/api/nombreEntrepreneur`);
  // const formationResponse = await axios.get(`${api}/api/nombreFormation`);
  // const inscriptionsResponse = await axios.get(`${api}/api/listeInscriptionsFormateur/${user_id}`);

  // setResume([
  //   { image: notebook, title: "Entrepreneur", statistic: entrepreneurResponse.data, number: 1 },
  //   { image: flag, title: "Formation", statistic: formationResponse.data, number: 1 },
  //   { image: file1, title: "Inscription confirmé", statistic: 12, number: 1 },
  //   { image: cup, title: "Inscription en attente", statistic: 12, number: 1 }
  // ]);
  fetchData()
  getListeInscriptionsFormateur()

}, [setTitle])

  return (
    <>
    {/* <Header title="Tableau de bord"/> */}
            {/* Card stats */}
            <div className="grid xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 gap-4 mb-4  mt-4 lg:px-0 px-4">
            {/* {resume.length} */}
             {resume.map((item, key)=>
              <ResumeCard item={item} key={key}/>
             )}
            </div>
           
          {/* </div>
        </div>
      </div> */}
      <div className="border-line p-6 min-h-[500px]  lg:mx-0 mx-4">
        <h5 className='text-xl font-semibold mb-4'>Historique des 5 derniers inscriptions</h5>
         <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
      <div className="overflow-hidden border rounded-md">
        {/* tableau de la liste des inscription */}
        <table className="min-w-full text-left text-sm font-light text-surface dark:text-white">
    <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
      <tr>
        <th scope="col" className="px-6 py-4">Candidats</th>
        <th scope="col" className="px-6 py-4">Titre de la formation</th>
        <th scope="col" className="px-6 py-4">Etat</th>
        <th scope="col" className="px-6 py-4">Date de création</th>
        <th scope="col" className="px-6 py-4">Action</th>
      </tr>
    </thead>
    <tbody>
      {inscriptions.map((inscription) => (
        <tr key={inscription.id}>
          <td className="flex px-6 py-4 font-medium gap-4">
            <ImageWithFallback src={api + '/storage/' + inscription?.user?.photo} alt={inscription?.user?.photo} className='w-12 h-12 rounded-full' defaultType='user'/>
            <div className="flex flex-col justify-between gap-2">
              <p>{inscription.user?.nom} {inscription.user?.donnee_perso1?.prenom}</p>
              <p>{inscription.user?.email}</p>
            </div>
          </td>
          <td className="px-6 py-4">{inscription.formation.titre}</td>
          <td>
            <Status text={inscription.etat}/>
          </td>
          <td>{new Date(inscription.created_at).toLocaleDateString()}</td>
          <td>
            <button className="btn-black-outline" onClick={() => navigate(`/formateur/inscription/${inscription.id}`)}>
              <img src={file} alt="..." className="w-5 h-5" />
              Consulter
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
      </div>
    </div>
  </div>
      </div>
     
    </>
  )
}

export default Dashboard