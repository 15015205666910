import React from 'react';
import img from '../../../assets/Logo.png'
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate()
    return (
        <footer className="w-screen mt-12 bg-slate-700 flex items-center p-4">
            <div className="grid lg:grid-cols-3 px-12 gap-8 justify-between py-6">
                {/* Première colonne */}
                <div className="flex flex-row items-center gap-4">
                    <img src={img} alt="logo" className="w-[73px]" />
                    <p className="text-white text-center text-lg">
                        NATURA GERME – L'avenir de la formation entrepreneuriale commence ici.
                    </p>
                </div>


                {/* Deuxième colonne */}
                <ul className="text-white flex flex-col gap-y-2 justify-center items-center">
                    <li className="font-semibold text-white text-xl">Liens utiles</li>
                    <li className="text-slate-400 hover:cursor-pointer">
                        <a href="https://tsenavitamalagasy.com/" className="text-lg hover:text-primary">
                            Tsena Vita Malagasy
                        </a>
                    </li>
                    <li className="text-slate-400 hover:cursor-pointer">
                        <a href="https://naturamadagascar.com/" className="text-lg hover:text-primary">
                            Natura Madagascar
                        </a>
                    </li>
                    <li className="text-slate-400 hover:cursor-pointer">
                        <a href="https://tsenavitamalagasy.com/" className="text-lg hover:text-primary">
                            I-KAONTY
                        </a>
                    </li>
                </ul>

                {/* Troisième colonne */}
                <div className="flex flex-col gap-8">
                    <p></p>
                    <p className="text-white text-lg">
                        Copyright © 2024 <a className="hover:text-primary text-lg" href="https://naturamadagascar.com/">Natura Madagascar</a>. <span className="text-lg">Tous droits réservés, créé par <a href="http://www.haisoa.com" className='c cursor-pointer hover:text-primary text-lg'>HAISOA</a>.</span>
                    </p>

                    {/* <p className="text-white">Tous droits réservés.</p> */}
                </div>
            </div>
        </footer>

    )
}