import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';

export default function Indicateur3({setPage}) {
    const { data, setData } = useOutletContext()
    function handleSubmit(e) {
        e.preventDefault()
        setPage(prev => prev += 1)
    }
    return (
        <>
            <form onSubmit={handleSubmit} className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Approvisionnement</span>
                <Select title={"Collecte les informations et les prix chez les fournisseurs"} values={selectInducateur} onChange={(e) => { setData({ ...data, approvisionnement1: e.target.value }) }} />
                <Select title={"Vérifie les marchandises à la livraison et traite bien les marchandises défectueuses"} values={selectInducateur} onChange={(e) => { setData({ ...data, approvisionnement2: e.target.value }) }} />
                <Select title={"Planifie les approvisionnements selon les besoins de l’entreprise"} values={selectInducateur} onChange={(e) => { setData({ ...data, approvisionnement3: e.target.value }) }} />
                <div  className='w-full flex flex-row justify-end items-center'>
                    <Button variant='contained' type='submit' style={{ backgroundColor: 'slategray' }}>
                        Suivant
                    </Button>
                </div>
            </form>
        </>
    )

}