import React, { useEffect, useRef, useState } from "react"
import { api } from "../../api"
import { Outlet, useNavigate } from "react-router-dom"
import Sidebar from "../components/Sidebar"
import axios from "axios"
import Header from "../components/Header"


const Layout = () => {
  const [profil, setProfil] = useState([])
  const navigate = useNavigate()
  const [title, setTitle] = useState('Tableau de bord')
  // const user_id = 10

  useEffect(() => {
    // if(localStorage.getItem('naturagerme-id') == null || 
    // localStorage.getItem('naturagerme-type') == null || 
    // localStorage.getItem('naturagerme-email') == null)navigate('/login')  
  })
  
  return (
    <>
    <div className="bg-neutral-50 flex min-h-screen  trainer-layout text-xs">
        <Sidebar/>
        <main className=" lg:ml-[290px] w-full mr-0 lg:mr-4">
        <Header title={title}></Header>
        <div className="lg:hidden block h-[60px]"></div>
        <Outlet context={{ setTitle }} /> 
        </main>
    </div>    
    </>
  )
}

export default Layout