import React from 'react';
import { IoCallOutline, IoLogoFacebook, IoLogoInstagram, IoLogoPinterest, IoLogoTwitter, IoMailOutline  } from 'react-icons/io5';

export default function HeaderContact() {
    return (
        <>
            <div className="w-screen bg-slate-800 flex flex-row justify-center py-4 ">
                <div className="w-11/12 flex flex-row justify-between items-center">
                    <div className={"flex max-md:flex-col md:flex-row gap-x-3 justify-start  max-md:items-start md:items-center"}>
                        <div className='flex flex-row justify-start items-center gap-x-3 text-slate-300'>
                            <IoCallOutline className='text-lg'/>
                            <span className='text-lg'>+261 34 81 818 21</span>
                        </div>
                        <div className='flex flex-row justify-start items-center gap-x-3 text-slate-300'>
                            <IoMailOutline className='text-lg'/>
                            <span className='text-lg'>contact@naturamadagascar.com</span>
                        </div>
                    </div>
                    <div className={"flex flex-row gap-x-3 justify-end items-center text-slate-300"}>
                        <IoLogoTwitter className='text-lg cursor-pointer'/>
                        <IoLogoFacebook className='text-lg cursor-pointer'/>
                        <IoLogoInstagram className='text-lg cursor-pointer'/>
                        <IoLogoPinterest className='text-lg cursor-pointer'/>
                    </div>
                </div>
            </div>
        </>
    )
}