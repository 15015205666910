export const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
}

export const formatDateTime = (dateString) => {
    if (!dateString) return "Non spécifiée";
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-FR', options).format(date);
  };
  
  export const formatSalary = (salary) => {
    if (!salary) return "Non spécifié";
    return new Intl.NumberFormat('fr-FR', { useGrouping: true }).format(parseInt(salary, 10));
  };
    
  export const  formatInDate =(isoDate)=> {
    const date = new Date(isoDate); // Convertir la chaîne ISO en objet Date
    const day = String(date.getDate()).padStart(2, "0"); // Ajouter un zéro si nécessaire
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }