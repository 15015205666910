import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';

export default function Indicateur6({ setPage }) {
    const { data, setData } = useOutletContext()
    function handleSubmit(e) {
        e.preventDefault()
        setPage(prev => prev += 1)
    }
    return (
        <>
            <form onSubmit={handleSubmit} className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Tenue des registres</span>
                <Select title={"Tient un journal clair et à jour"} values={selectInducateur} onChange={(e) => { setData({ ...data, tenueRegistre1: e.target.value }) }} />
                <Select title={"Tient un compte client"} values={selectInducateur} onChange={(e) => { setData({ ...data, tenueRegistre2: e.target.value }) }} />
                <Select title={"Calcule régulièrement les bénéfices et pertes"} values={selectInducateur} onChange={(e) => { setData({ ...data, tenueRegistre3: e.target.value }) }} />
                <div className='w-full flex flex-row justify-end items-center'>
                    <Button variant='contained' type='submit' style={{ backgroundColor: 'slategray' }}>
                        Suivant
                    </Button>
                </div>
            </form>
        </>
    )

}
