import React from 'react';

export default function Select({values=[],title,onChange}){
    return (
        <div className='flex flex-col justify-start items-start gap-y-1 w-full'>
            <label className='input_label'>{title}</label>
            <select name="" id="" className="input w-full" onChange={onChange}>
                
                {
                    values.map((item, index) => (
                        <option key={index} value={item.value} label={item.label} />
                    ))
                }
            </select>
        </div>
    )
}