import React, { useEffect, useState } from 'react';
import img from '../../../../assets/black.jpg'
import CardFormation from '../../../components/card_formation';
import Button from '../../../components/btn/Button';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../api';
import axios from 'axios';
import { Skeleton } from '@mui/material';
export default function Formation() {
    const [data, setData] = useState([
        // {
        //     id: 1,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 2,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 3,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 4,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 5,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 6,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },

    ])
    const navigate = useNavigate();

    useEffect(() => {
        // Remplacer cette URL par l'URL de votre API Laravel
       
            axios.get(api + '/api/getFormationsAcc')
                .then(response => {
                    setData(response.data);
                    console.log(response.data.length);
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des formations:", error);
                });
       
    }, []);

    return (
        <div className='w-screen bg-slate-50 flex flex-col  items-center py-6'>
            
                    <div className='w-11/12 flex flex-row py-4 justify-between items-center '>
                        <span className='text-[#2C2F24] text-3xl  font-medium'>Formation(s)</span>
                        <button onClick={() => { navigate('/Formation') }} className='text-blue-600'>Voir plus</button>
                    </div>
                
            <div className='w-11/12 flex flex-row flex-wrap justify-start items-start gap-5'>

                {
                    data.length>=1 ? (

                        data.map((item, index) => (
                            <CardFormation item={item} key={index} />
                        ))

                    ) : (
                        <>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                            <div className="flex flex-col bg-white rounded-[4px] shadow-md gap-y-2 shadow-slate-300/50 h-[250px] w-[250px]">
                                <Skeleton animation="pulse" height={150} variant="rectangular" sx={{ bgColor: "white" }} />
                                <div className="w-full ml-4 mt-3 flex flex-col gap-y-1">
                                    <Skeleton animation="pulse" height={20} width={100} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />
                                    <Skeleton animation="pulse" width={220} variant="text" sx={{ bgColor: "white" }} />

                                </div>

                            </div>
                        </>
                    )
                }

            </div>
        </div>
    )
}